import { useRef, useState } from "react"
import { useChangeVisibility } from "react-visibility-detector"

export const useOnVisibleOnce = () => {

    const targetRef = useRef(null)
    const visibleBefore = useRef(false)
    const [animateFlag, setAnimateFlag] = useState(0)

    const onVisibilityChange = ({ visible }) => {
        if(window.innerWidth < 900) return
        if(visibleBefore.current) return
        if(visible) setAnimateFlag(1)
        visibleBefore.current = visible
    }

    useChangeVisibility({ targetRef, onVisibilityChange })

    return [targetRef, animateFlag]
}
