import * as React from "react"
import styled from "@emotion/styled"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import SideAppearAnimation from "../animations/SideAppearAnimation"
import { useOnVisibleOnce } from "../hooks/useOnVisibleOnce"

const Wrapper = styled.div({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginBottom: "5em",
    position: "relative"
}, ({ inverted, animate }) => ({
    flexDirection: inverted ? "row" : "row-reverse",
    animation: animate ? `${SideAppearAnimation("-100%", inverted ? "left" : "right")} 1.5s ease` : "none"
}))

const SectorImage = styled(GatsbyImage)({
    flexBasis: "40%",
    borderRadius: "1.75em",
    boxShadow: "0 0 8px rgba(0, 0, 0, 0.4)",
    transition: "flex-basis 300ms",
    marginTop: "5em",
    marginBottom: "5em",
    "&:hover": {
        flexBasis: "45%"
    },
    "@media(max-width: 900px)": {
        marginTop: "8em",
        marginBottom: "8em",
    },
    "@media(max-width: 850px)": {
        flexBasis: "100%",
        marginTop: "5em",
        marginBottom: "5em",
    }
})

const TextWrapper = styled.div({
    flexBasis: "50%",
    "@media(max-width: 850px)": {
        flexBasis: "100%"
    }
})

const SectorTitle = styled.p({
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "2em",
    "&:before": {
        content: "''",
        display: "block",
        borderTop: "2px solid #ccc",
        width: "100%",
        marginBottom: "0.7em",
    },
    "&:after": {
        content: "''",
        display: "block",
        borderBottom: "2px solid #ccc",
        width: "100%",
        marginTop: "0.7em",
    }
})

const SectorParagraph = styled.p({
    textAlign: "justify",
    fontSize: "1.2em",
    lineHeight: "1.4em"
})

export default function Sector({ title, paragraphs, image, inverted, tag }) {

    const loadedImage = getImage(image)
    const [ref, animate] = useOnVisibleOnce()

    const paragraphList = paragraphs.map((paragraph, i) => (
        <SectorParagraph key={i}>{paragraph}</SectorParagraph>
    ))

    return (
        <Wrapper ref={ref} animate={animate} id={tag} inverted={inverted}>
            <TextWrapper>
                <SectorTitle>{title}</SectorTitle>
                {paragraphList}
            </TextWrapper>
            <SectorImage image={loadedImage} alt={title} />
        </Wrapper>
    )
}
