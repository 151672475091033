import * as React from "react"
import styled from "@emotion/styled"

const Card = styled.div({
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    borderRadius: "1em",
    border: "1px solid #ccc",
    margin: "0 auto",
    marginBottom: "2em",
    width: "70%",
    paddingLeft: "3.5em",
    paddingRight: "3.5em",
    backdropFilter: "blur(4px)",
    "@media(max-width: 480px)": {
        paddingLeft: "2em",
        paddingRight: "2em"
    }
})

const Title = styled.p({
    textAlign: "center",
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    fontSize: "2.5em",

    "&:after": {
        content: "''",
        display: "block",
        borderBottom: "2px solid #ccc",
        width: "100%",
        marginTop: "0.7em",
    },

    "@media(max-width: 400px)": {
        fontSize: "2em"
    }
})

const Paragraph = styled.p({
    fontSize: "1.2em",
    marginBottom: "2em",
    textAlign: "justify"
})

export default function PageHeader({ title, paragraphs, children }) {

    const paragraphsList = paragraphs.map((paragraph, i) => (
        <Paragraph key={i}>
            {paragraph}
        </Paragraph>
    ))

    return (
        <Card>
            <Title>{title}</Title>
            {paragraphsList}
            {children}
        </Card>
    )
}
