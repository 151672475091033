import { keyframes } from "@emotion/react"

export default (offsetX, side) => keyframes`
    0% {
        opacity: 0%;
        ${side}: ${offsetX}
    }
    100% {
        opacity: 100%;
        ${side}: 0;
    }
`
