import * as React from "react"
import MainContainer from "../components/MainContainer"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Sector from "../components/Sector"
import PageHeader from "../components/PageHeader"
import SEO from "../components/SEO"

const SectorsContainer = styled.div({
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    padding: "3em",
    marginTop: "3em",
    backdropFilter: "blur(6px)"
})

export default function SectorsPage() {

    const data = useStaticQuery(AllSectors)
    const sectors = data.sectors.nodes
    const header = data.header
    const { seoTitle, seoDescription, title, paragraphs } = header

    const sectorsList = sectors.map(({ id, title, paragraphs, image, tag }, i) => (
        <Sector key={id} title={title} paragraphs={paragraphs} image={image} tag={tag} inverted={i % 2 == 0} />
    ))

    return (
        <MainContainer>
            <SEO title={seoTitle} description={seoDescription} pagename="sectors" />
            <PageHeader title={title} paragraphs={paragraphs} />
            <SectorsContainer>
                {sectorsList}
            </SectorsContainer>
        </MainContainer>
    )
}

const AllSectors = graphql`
    query AllSectors {
        sectors: allSectorsJson {
            nodes {
                id
                title
                paragraphs
                tag
                image {
                    childImageSharp {
                        gatsbyImageData(width: 600, height: 450)
                    }
                }
            }
        }
        header: headersJson(id: {eq: "sectors"}) {
            title
            paragraphs
            seoTitle
            seoDescription
        }
    }
`
